import { FC, useState, useEffect } from 'react'

import useStyles from './styles'
import axios from 'axios'
import notifyer from 'src/common/notifyer'

interface IClientInfo {
  ip: string
}

interface IGeoInfo {
  country: string
  timezone: string
}

const GeoDetails: FC<IClientInfo> = ({
  ip,
}) => {
  const classes = useStyles()

  const [geoDetails, setGeoDetails] = useState<IGeoInfo | null>(null)

  useEffect(() => {
    const storedGeoDetails = sessionStorage.getItem(`geoDetails-${ip}`)
    if (ip?.length && storedGeoDetails) {
      setGeoDetails(JSON.parse(storedGeoDetails as string))
    }

    if (ip?.length && !storedGeoDetails) {
      (async () => {
        try {
          const response = await axios.get(`https://ipinfo.io/${ip}?token=e75bacb9c6aee7`)

          if (response && response.data)  {
            setGeoDetails(JSON.parse(JSON.stringify(response.data)))
            sessionStorage.setItem(`geoDetails-${ip}`, JSON.stringify(response.data))
          } else {
            notifyer.warning('User geo info empty response')
            setGeoDetails(null)
          }
        } catch {
          notifyer.warning('User geo info request error')
          setGeoDetails(null)
        }
      })()
    }
  }, [ip])

  if (!geoDetails) {
    return null
  }

  const geoDetailsList = [
    {ip},
    {country: geoDetails?.country},
    {timezone: geoDetails?.timezone},
  ]

  return (
    <>
      {geoDetailsList.map((item) => {
        const [key, value] = Object.entries(item)[0]

        if (!value) {
          return null
        }

        return (
          <p
            key={key}
            className={classes.mainInfoItem}>
            <span className={classes.mainInfoHighlited}>
              {key}
            </span>
            <span className={classes.mainInfoValue}>
              {value}
            </span>
          </p>
        )
      })}
    </>
  )
}

export default GeoDetails
